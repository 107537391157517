import React from "react";
import PropTypes from "prop-types";

import GoogleGoldStarsFull from "@images/testemonials/gold_review_starts.svg";
import Google from "../../../static/images/icons/google.svg";

import "@styles/components/GoogleReviewBox.sass";

const propTypes = {
  authorTitle: PropTypes.string.isRequired,
  authorImage: PropTypes.string,
  reviewText: PropTypes.shape({
    en: PropTypes.string,
    bg: PropTypes.string
  }).isRequired,
  currentLanguage: PropTypes.string
};

const GoogleReviewBox = ({ authorTitle, authorImage, reviewText, currentLanguage }) => {
  PropTypes.checkPropTypes(propTypes, { authorTitle, authorImage, reviewText, currentLanguage }, "prop", "GoogleReviewBox");

  const fallbackLanguage = currentLanguage === "en" ? "bg" : "en"; // Fallback language in case the current language is not available
  const translated = reviewText[currentLanguage] || reviewText[fallbackLanguage] || "";

  return (
    <a
      id="google-rating-view-box-wrapper"
      href="https://search.google.com/local/reviews?placeid=ChIJk4HGts6HqkARXRUkNRbL6RQ&q=Boleron&authuser=0&hl=en&gl=UA"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="carousel-item-vertical">
        <div className="carousel-item-inner">
          <div className="testimonials-user">
            <img className="testimonials-user-img" src={authorImage} loading="lazy" alt={authorTitle} crossOrigin="anonymous" />
            <div className="testimonials-name-rating">
              <p className="is-capitalized has-text-weight-bold ">{authorTitle}</p>
              <GoogleGoldStarsFull className="testimonials-google-starts-icon" width={100} />
            </div>
          </div>

          <div className="mt-3 pr-3 has-text-left">
            <p className="testimonials-user-review">{translated}</p>
          </div>

          <Google width={25} height={25} className="testimonials-google-icon-vertical" />
        </div>
      </div>
    </a>
  );
};

GoogleReviewBox.defaultProps = {
  authorImage: "",
  currentLanguage: ""
};

GoogleReviewBox.propTypes = propTypes;

export default GoogleReviewBox;
