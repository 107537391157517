import React from "react";
import RootLayout from "./src/components/RootLayout";

export const wrapRootElement = ({ element, props }) => {
  return <RootLayout {...props} element={element} />;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  let last20Routes = JSON.parse(sessionStorage.getItem("last20Routes")) || [];

  sessionStorage.setItem("currentLocationObj", JSON.stringify(location));
  sessionStorage.setItem("prevLocationObj", JSON.stringify(prevLocation));

  last20Routes.unshift(location);

  last20Routes = last20Routes.slice(0, 20);

  sessionStorage.setItem("last20Routes", JSON.stringify(last20Routes));
};
