import axios from "axios";
import qs from "qs";

const { CancelToken } = axios;
const sourceArr = [];

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const request = (method, url, data, headers, accessToken, returnData) => {
  const source = CancelToken.source();
  sourceArr.push(source);
  const config = {
    method,
    url: `${url}`,
    headers: {},
    cancelToken: source.token,
    params: {},
    paramsSerializer: ""
  };

  if (accessToken) {
    config.headers = { Authorization: `Bearer ${accessToken}` };
  }

  if (headers) {
    config.headers = Object.assign(config.headers, headers);
  }
  if (data) {
    switch (method) {
      case GET:
        config.paramsSerializer = params => qs.stringify(params, { arrayFormat: "repeat" });
        config.params = data;
        break;
      case POST:
        config.data = data;
        break;
      case PUT:
        config.data = data;
        break;
      case DELETE:
        config.data = data;
        break;
      default:
        break;
    }
  }

  if (returnData) {
    return axios(config)
      .then(response => response)
      .catch(error => {
        throw error;
      });
  }
  return axios(config).catch(error => {
    throw error;
  });
};

export const cancelRequests = () => {
  const arrLength = sourceArr.length;
  for (let i = 0; i < arrLength; i++) {
    sourceArr[i].cancel();
  }
};
