export const env = process.env.GATSBY_ENV || "DEVELOPMENT";

let urlPrefix;
let urlFibank;
let magicLinkRedirect;
let trackingIds;
let webSocketPrefix;
if (env === "DEVELOPMENT") {
  urlPrefix = "http://localhost:1337/vivacom"; // "http://10.0.2.2:1337/"; for android app
  webSocketPrefix = "ws://localhost:1337/vivacom";
  urlFibank = "https://mdpay-test.fibank.bg/ecomm_v2/ClientHandler";
  magicLinkRedirect = "http://localhost:8000";
} else if (env === "PRODUCTION") {
  urlPrefix = "https://api.boleron.bg:443/vivacom";
  webSocketPrefix = "wss://api.boleron.bg/vivacom";
  urlFibank = "https://mdpay.fibank.bg/ecomm_v2/ClientHandler";
  magicLinkRedirect = "https://insurance.vivacom.bg";
} else if (env === "STAGING") {
  urlPrefix = "https://boleron-devapi.azurewebsites.net/vivacom";
  webSocketPrefix = "wss://boleron-devapi.azurewebsites.net/vivacom";
  urlFibank = "https://mdpay-test.fibank.bg/ecomm_v2/ClientHandler";
  magicLinkRedirect = "https://boleron-webdemo.azurewebsites.net";
}
export default {
  urlPrefix,
  urlFibank,
  magicLinkRedirect,
  webSocketPrefix,
  trackingIds
};
