import _get from "lodash.get";
import moment from "moment";
import { toJS } from "mobx";
import { bgPhonePrefix } from "../config/activeProducts/helpers";
import { env } from "../config/env";

// import { getYearsByEgn } from "../helpers/CascoHelpers";

export const timeoutPromise = (timeout, err, promise) =>
  new Promise((resolve, reject) => {
    promise.then(resolve, reject);
    setTimeout(reject.bind(null, err), timeout);
  });

export const getLanguage = () => "ro";
// return i18n.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "bg"; //todo

export const parseISOLocal = dateString => {
  const b = dateString.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
};

export const postMessageToNative = (e, message) => {
  if (typeof window !== `undefined` && typeof window.ReactNativeWebView !== `undefined`) {
    window.ReactNativeWebView.postMessage(message);
  }
};

export const isRunnigOnNative = () => typeof window !== `undefined` && typeof window.ReactNativeWebView !== `undefined`;

export const validateEmail = text => {
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
  if (text && text.length > 0 && reg.test(text.trim()) === false) {
    return "Въведете валиден имейл";
  }
  return true;
};

export const removePhonePrefixAndSpaces = phoneNumber => {
  const escapedStringToRemove = bgPhonePrefix.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  // Create a regular expression with the escaped stringToRemove and global flag
  const regex = new RegExp(escapedStringToRemove, "g");
  // Remove the specified string and spaces from the original string
  return phoneNumber.replace(regex, "").replace(/\s/g, "");
};
export const validateBgPhoneNumber = text => {
  const regMobile = /^[0]{0,1}(?:8|9)\d{8}$/;
  if (text && text.length > 0 && regMobile.test(text.trim()) === false) {
    return false;
  }
  return true;
};

export const formatBGPhone = text => {
  let phone;
  if (text) {
    phone = text.replace(/\s/g, "").replace(/\-/g, "").replace("+359", "");
    // remove leading 0
    if (phone.startsWith("0") && phone.length === 10) {
      phone = phone.slice(1);
    }

    if (validateBgPhoneNumber(phone)) {
      return phone;
    }
  }
  // remove leading 0

  return "";
};

export const addBgPrefixToFormattedPhone = text => {
  const formattedPhone = formatBGPhone(text);
  if (formattedPhone) {
    return `${bgPhonePrefix}${formattedPhone}`;
  }
  return "";
};

export const formatThousands = num => {
  const n = String(num);
  const p = n.indexOf(".");
  return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) => (p < 0 || i < p ? `${m} ` : m));
};

export const validateLkDate = text => {
  const reg = /^\d{2}-\d{2}-\d{4}$/;
  if (text && text.length > 0 && reg.test(text) === false) {
    return "Въведете датата във формат ДД-ММ-ГГГГ";
  }
  return true;
};

export const validateLkNum = text => {
  const reg = /^\d{9}$/;
  if (text && text.length > 0 && reg.test(text) === false) {
    return "Въведете валиден номер";
  }
  return true;
};

const EGN_WEIGHTS = [2, 4, 8, 5, 10, 9, 7, 3, 6];

// const LNCH_WEIGHTS = [21, 19, 17, 13, 11, 9, 7, 3, 1];

export const validateEGN = (text, product) => {
  if (text && text.length === 10) {
    if (getYearsByEgn(text) >= 74 && product === "TRA") return "Не се застраховат лица над 74 години";
    if (getYearsByEgn(text) >= 70 && (product === "EXT" || product === "MTN")) return "Не се застраховат лица над 70 години";
    if (getYearsByEgn(text) >= 65 && product === "HLT") return "Не се застраховат лица над 65 години";

    /* Calculate checksum */
    let egnsum = 0;
    // let lnchsum = 0;
    for (let i = 0; i < 9; i++) {
      const numberChar = Number(text.charAt(i));
      if (numberChar >= 0) {
        egnsum += numberChar * EGN_WEIGHTS[i];
        // lnchsum += numberChar * LNCH_WEIGHTS[i];
      }
    }
    let validChecksum = egnsum % 11;
    // const valid_lnchsum = lnchsum % 10;
    if (validChecksum === 10) {
      validChecksum = 0;
    }

    return validChecksum === Number(text.charAt(9));
    // За ЛНЧ valid_lnchsum === Number(text.charAt(9));
  }

  if (text) {
    return "Въведете валидно ЕГН";
  }

  return true;
};

export const validateEIKOrEgn = text => {
  if (validateEGN(text) === true) {
    return true;
  }
  if (validateEIK(text) === true) {
    return true;
  }
  return "Невалидно ЕГН / ЕИК";
};

export const validateDate = text => {
  if (text) {
    const formattedDate = moment(text, "DD-MM-YYYY", true);
    if (formattedDate.isValid()) {
      const isAfter = formattedDate ? formattedDate.isAfter("1980-01-01", "day") : false;
      const isBefore = formattedDate ? formattedDate.isBefore(moment().add(1, "day"), "day") : false;
      return isAfter && isBefore;
    }
    return false;
  }
  return false;
};
export const checkDate = text => (validateDate(text) ? true : "Невалидна дата");

const FIRST_SUM_9DIGIT_WEIGHTS = [1, 2, 3, 4, 5, 6, 7, 8];
const SECOND_SUM_9DIGIT_WEIGHTS = [3, 4, 5, 6, 7, 8, 9, 10];
const FIRST_SUM_13DIGIT_WEIGHTS = [2, 7, 3, 5];
const SECOND_SUM_13DIGIT_WEIGHTS = [4, 9, 5, 7];
const calculateNinthDigitInEIK = digits => {
  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += digits[i] * FIRST_SUM_9DIGIT_WEIGHTS[i];
  }
  const remainder = sum % 11;
  if (remainder !== 10) {
    return remainder;
  }
  // remainder= 10
  let secondSum = 0;
  for (let i = 0; i < 8; i++) {
    secondSum += digits[i] * SECOND_SUM_9DIGIT_WEIGHTS[i];
  }
  const secondRem = secondSum % 11;
  if (secondRem !== 10) {
    return secondRem;
  }
  // secondRemainder= 10
  return 0;
};

const calculateThirteenthDigitInEIK = digits => {
  const ninthDigit = calculateNinthDigitInEIK(digits);
  if (ninthDigit !== digits[8]) {
    return "Incorrect 9th digit in EIK-13.";
  }
  // 9thDigit is a correct checkSum. Continue with 13thDigit
  let sum = 0;
  for (let i = 8, j = 0; j < 4; i++, j++) {
    sum += digits[i] * FIRST_SUM_13DIGIT_WEIGHTS[j];
  }
  const remainder = sum % 11;
  if (remainder !== 10) {
    return remainder;
  }
  // remainder= 10
  let secondSum = 0;
  for (let i = 8, j = 0; j < 4; i++, j++) {
    secondSum += digits[i] * SECOND_SUM_13DIGIT_WEIGHTS[j];
  }
  const secondRem = secondSum % 11;
  if (secondRem !== 10) {
    return secondRem;
  }
  // secondRemainder= 10
  return 0;
};
export const validateEIK = text => {
  const reg = /^\d+$/;
  const eik = text ? text.trim() : "";
  if (eik && (eik.length === 9 || eik.length === 13) && reg.test(eik)) {
    if (eik.length === 9) {
      const digits = Array.from(eik).map(el => parseInt(el));
      const ninthDigit = calculateNinthDigitInEIK(digits);
      return ninthDigit === digits[8];
    }
    const digits = Array.from(eik).map(el => parseInt(el));
    const thirteenDigit = calculateThirteenthDigitInEIK(digits);
    return thirteenDigit === digits[12];
  }
  return false;
};
export const validateEIKMobile = text => {
  const isValid = validateEIK(text);
  if (!isValid) {
    return "Невалиден ЕИК";
  }
  return true;
};
export const pad = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};

export const genEgn = age => {
  const date = new moment().subtract(3, "days");
  const year = pad((date.year() - age) % 100, 2);
  const month = pad(year >= 2000 ? date.month() + 40 : date.month(), 2);
  const day = pad(date.date(), 2);
  // random number 0 - 925
  const rnd = pad(getRandomInt(0, 925), 3);

  const egn = year + month + day + rnd;

  /* Calculate checksum */
  let egnsum = 0;
  for (let i = 0; i < 9; i++) {
    const numberChar = Number(egn.charAt(i));
    if (numberChar >= 0) {
      egnsum += numberChar * EGN_WEIGHTS[i];
    }
  }
  let valid_checksum = egnsum % 11;
  if (valid_checksum === 10) {
    valid_checksum = 0;
  }

  return egn + valid_checksum;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const checkCyrillic = text => {
  const reg = /^[0-9а-я\s.,-]+$/i;
  if (text && text.length > 0 && reg.test(text) === false) {
    return "Моля, използвайте кирилица";
  }
  return true;
};
export const checkCyrillicAndDitto = text => {
  const reg = /^[0-9а-я\s.,\-"'']*$/i;
  if (text !== null && text !== undefined) {
    if (reg.test(text) === false) {
      return "Моля, използвайте кирилица";
    }
  }
  return true;
};

export const checkCyrillicAndNumbers = text => {
  const reg = /^[0-9а-я\s.-[\] ,\(\)]+$/i;
  if (text && text.length > 0 && reg.test(text) === false) {
    return "Моля, използвайте кирилица";
  }
  return true;
};

export const checkPositiveAge = age => {
  if (age < 0) {
    return "Въведете валидна възраст";
  }
  return true;
};

export const checkHLTAge = age => {
  if (age > 64) {
    return "Не се застраховат лица над 64 години";
  }
  if (age < 1) {
    return "Застраховат се лица на 1 и повече години";
  }
  return true;
};

export const checkHWPTitularAge = age => age <= 64 && age >= 18;

export const minCustomCarPrice = value => value >= 3000;
export const maxCustomCarPrice = value => value <= 200000;

export const checkMountainAge = age => {
  if (age > 70) {
    return "Не се застраховат лица над 70 години";
  }
  return true;
};
export const checkTravelAge = age => {
  if (age > 74) {
    return "Не се застраховат лица над 74 години";
  }
  return true;
};
export const checkUnder18 = text => {
  if (text && text.length > 0 && getYearsByEgn(text) > 18) {
    return "Възрастта на детето трябва да е под 18 години";
  }
  return true;
};

export const checkUnder19 = text => {
  if (text && text.length > 0 && getYearsByEgn(text) > 19) {
    return "Въведеното ЕГН не отговаря на възрастовия период за дете (до 19 години).";
  }
  return true;
};

export const checkUnder17 = text => {
  if (text && text.length > 0 && getYearsByEgn(text) > 17) {
    return "Въведеното ЕГН не отговаря на възрастовия период за дете (до 17 години).";
  }
  return true;
};

export const checkCyrillicAndAllowNumbers = text => {
  const reg = /^[а-я\s-]+$/i;
  const textWithoutNumbers = text.replace(/[0-9]/g, "").replace(/,/g, "").replace(/"/g, "").replace(/№/g, "").replace(/\./g, "");
  if (textWithoutNumbers && textWithoutNumbers.length > 0 && reg.test(textWithoutNumbers) === false) {
    return "Моля, използвайте кирилица";
  }
  return true;
};
export const checkYear = text => {
  const reg = /^(19|20)\d{2}$/;
  if (text && text.length > 0 && reg.test(text) === false) {
    return "Моля, въведете валидна година";
  }
  return true;
};

export const checkDoorCount = text => {
  if (text && (parseInt(text) >= 2 && parseInt(text) <= 7) === false) {
    return "Невалиден брой врати";
  }
  return true;
};

export const checkLatin = text => {
  const reg = /^[a-z\s-]+$/i;
  if (text && text.length > 0 && reg.test(text) === false) {
    return "Моля, използвайте латински букви";
  }
  return true;
};
export const checkLatinAndAllowNumbers = text => {
  const textWithoutNumbers = text.replace(/[0-9]/g, "");
  const reg = /^[a-z\s-]+$/i;
  if (textWithoutNumbers && textWithoutNumbers.length > 0 && reg.test(textWithoutNumbers) === false) {
    return "Моля, използвайте латински букви";
  }
  return true;
};
export const checkRegNumber = text => {
  const regNo = transliterateRegNo(text);
  const reg = /^[ABEKMHOPCTYX]{1,2}[0-9ABEKMHOPCTYX]{4}[ABEKMHOPCTYX0-9]{1,2}$/;
  if (regNo && regNo.length > 0 && reg.test(regNo.replace(/\s/g, "")) === false) {
    return "Невалиден регистрационен номер.";
  }
  return true;
};
export const checkPolicyNumber = text => {
  const reg = /^BG\/[0-9]{2}\/[0-9]+$/g;
  if (text && reg.test(text) === false) {
    return "Невалиден номер на полицата.";
  }
  return true;
};
export const checkTalon = text => {
  const reg = /^[0-9]{9}$/;
  if (text && text.length && reg.test(text.replace(/\s/g, "")) === false) {
    return "Невалиден номер на талон.";
  }
  return true;
};
export const checkVin = text => {
  const reg = /^[a-zA-Z0-9]{9}[a-zA-Z0-9]{3}[0-9]{5}$/;
  if (text && text.length > 0 && reg.test(text) === false) {
    return "Невалиден номер на рама.";
  }
  return true;
};
export const checkPercent = text => {
  const reg = /^[0-9]{1,3}$/;
  const textInt = parseInt(text);
  if (text && (reg.test(text) === false || textInt > 100 || textInt < 1)) {
    return "Въведеният процент прябва да бъде въведен само с цифри. Стойностите могат да бъдат цели числа между 1 и 100.";
  }
  return true;
};

const VIN_MANUFACTURER_CODES_3 = [
  "AAV",
  "AC5",
  "ADD",
  "AFA",
  "AHT",
  "NNA",
  "PNA",
  "SB1",
  "SBM",
  "SDB",
  "SED",
  "SFA",
  "SKF",
  "TMA",
  "TMB",
  "TMK",
  "TMT",
  "TRU",
  "TSM",
  "VNK",
  "VNV",
  "VX1",
  "WF0",
  "WME",
  "WMW",
  "WMX",
  "W0L",
  "W0V",
  "WUA",
  "Y6D",
  "ZLA",
  "5YJ"
];

const VIN_MANUFACTURER_CODES_2 = [
  "JA",
  "JD",
  "JF",
  "JH",
  "JK",
  "JL",
  "JM",
  "JN",
  "JS",
  "JT",
  "JY",
  "KL",
  "KM",
  "KN",
  "KP",
  "LA",
  "LB",
  "LC",
  "LD",
  "LE",
  "LF",
  "LG",
  "LH",
  "LJ",
  "LK",
  "LL",
  "LM",
  "LP",
  "LS",
  "LT",
  "LU",
  "LV",
  "LZ",
  "L5",
  "L4",
  "L8",
  "MA",
  "MB",
  "MC",
  "MD",
  "ME",
  "MH",
  "ML",
  "MM",
  "MN",
  "MP",
  "MR",
  "MS",
  "SH",
  "NL",
  "NM",
  "PE",
  "PL",
  "RF",
  "SA",
  "SC",
  "SU",
  "UU",
  "U5",
  "VF",
  "VS",
  "VW",
  "WP",
  "WA",
  "WB",
  "WD",
  "WV",
  "XL",
  "XT",
  "XU",
  "XW",
  "YB",
  "YS",
  "YV",
  "ZA",
  "ZD",
  "ZF",
  "ZH",
  "1B",
  "1C",
  "1D",
  "1F",
  "1G",
  "1H",
  "1J",
  "1L",
  "1M",
  "1N",
  "1P",
  "1R",
  "1V",
  "2A",
  "2F",
  "2G",
  "2H",
  "2T",
  "2W",
  "4F",
  "4S",
  "4V",
  "5T",
  "6F"
];

export const checkVinMan = text => {
  if (text && text.length === 17) {
    if (VIN_MANUFACTURER_CODES_2.includes(text.substring(0, 2)) || VIN_MANUFACTURER_CODES_3.includes(text.substring(0, 3))) {
      const reg = /^[A-Z0-9]{9}[A-Z0-9]{3}[0-9]{5}$/;
      return reg.test(text);
    }
  }

  return false;
};

export const checkExperience = text => {
  if (text && (parseInt(text) > 0 && parseInt(text) <= 90) === false) {
    return "Невалиден стаж";
  }
  return true;
};

export const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));

export const localDate = date => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  if (date) {
    return `${day}.${month}.${year} `;
  }
  return "";
};

export const getExpiry = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() - 2000 + 3;

  return (month < 10 ? `0${month}` : month.toString()) + year.toString();
};

export const isCardExpired = expiryData => {
  if (expiryData && expiryData.length === 4) {
    const endOfMonth = moment().endOf("month");
    const cardExpiryDate = moment(expiryData, "MMYY").endOf("month");

    return cardExpiryDate.isValid() && cardExpiryDate.isBefore(endOfMonth);
  }
  return false;
};

export const localDateTime = date => {
  if (date) {
    const min = date.getMinutes();
    const hours = date.getHours();
    const secs = date.getSeconds();

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} г. ${hours < 10 ? +"0" + hours : hours}:${min < 10 ? `0${min}` : min}:${secs < 10 ? `0${secs}` : secs}`;
  }
  return "";
};

export const round = function (value) {
  return Number(`${Math.round(`${value}e2`)}e-2`);
};
export const round4 = function (value) {
  return parseFloat(value.toFixed(4));
};
export const roundUp = function (num, precision) {
  num = parseFloat(num);
  if (!precision) return num;
  return Math.ceil(num / precision) * precision;
};
export const roundDown = function (num, precision) {
  num = parseFloat(num);
  if (!precision) return num;
  return Math.floor(num / precision) * precision;
};
export const transliterateRegNo = text => {
  let rep;
  if (text) {
    rep = text.toUpperCase().replace(/\s/g, "");
  } else {
    return "";
  }

  rep = rep
    .replace(/В/g, "B")
    .replace(/в/g, "B")
    .replace(/А/g, "A")
    .replace(/а/g, "A")
    .replace(/Е/g, "E")
    .replace(/е/g, "E")
    .replace(/К/g, "K")
    .replace(/к/g, "K")
    .replace(/М/g, "M")
    .replace(/м/g, "M")
    .replace(/Н/g, "H")
    .replace(/н/g, "H")
    .replace(/О/g, "O")
    .replace(/о/g, "O")
    .replace(/Р/g, "P")
    .replace(/р/g, "P")
    .replace(/С/g, "C")
    .replace(/с/g, "C")
    .replace(/Т/g, "T")
    .replace(/т/g, "T")
    .replace(/У/g, "Y")
    .replace(/у/g, "Y")
    .replace(/Х/g, "X")
    .replace(/х/g, "X");
  return rep;
};

export const lat2Cyr = text => {
  let rep;
  if (text) {
    rep = text.toUpperCase();
  } else {
    return "";
  }

  rep = rep
    .replace(/A/g, "А")
    .replace(/B/g, "Б")
    .replace(/C/g, "")
    .replace(/D/g, "Д")
    .replace(/E/g, "Е")
    .replace(/F/g, "Ф")
    .replace(/G/g, "Г")
    .replace(/H/g, "Х")
    .replace(/I/g, "И")
    .replace(/J/g, "Ж")
    .replace(/K/g, "К")
    .replace(/L/g, "Л")
    .replace(/M/g, "М")
    .replace(/N/g, "Н")
    .replace(/O/g, "О")
    .replace(/P/g, "П")
    .replace(/Q/g, "Я")
    .replace(/R/g, "Р")
    .replace(/S/g, "С")
    .replace(/T/g, "Т")
    .replace(/U/g, "У")
    .replace(/V/g, "В")
    .replace(/W/g, "")
    .replace(/X/g, "Х")
    .replace(/Y/g, "")
    .replace(/Z/g, "З");
  return rep;
};

export const get_browser = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Edge", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1]
  };
};

export const isSupported = () => {
  const browser = get_browser();
  let supported = true;
  if (browser.name === "MSIE" || browser.name === "IE") {
    supported = false;
  }
  return supported;
};

export const getPersonNumbers = (screenId, adults, children) => {
  const parentNo = screenId <= adults ? screenId : undefined;
  const childNo = screenId - adults >= 1 && screenId - adults <= children ? screenId - adults : undefined;
  return {
    parentNo,
    childNo
  };
};

export const capitalizeFirstLetter = string => {
  if (!string) {
    return undefined;
  }
  if (string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return string.charAt(0).toUpperCase();
};
export const capitalizeOnlyFirstLetter = string => {
  if (!string) {
    return undefined;
  }
  if (string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string.charAt(0).toLowerCase();
};
export const isUpperCase = str => {
  if (!str) return false;
  return str === str.toUpperCase();
};

export const isValidEmail = email => {
  const reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  return reg.test(email.trim());
};

export const getCookie = name => {
  try {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
  } catch (err) {
    //  console.log(err);
  }
  return null;
};

export const removeSpacesFromString = str => (str ? str.replace(/\s/g, "") : "");

export const getPromoPrice = (price, percentage) => ((percentage / 100) * parseFloat(price)).toFixed(2);

export const getPromoPriceObj = (priceObj, percentage) => {
  if (priceObj.promo) {
    return priceObj;
  }
  const totalDiscount = getPromoPrice(priceObj.total, percentage);

  const installmentsArray = priceObj.installments.map(ins => ({
    ...ins,
    totalPromo: round(ins.total - getPromoPrice(ins.total, percentage))
  }));

  const totalPromoPriceByInstallmets = round(installmentsArray.reduce((accumulator, currValue) => accumulator + Number(currValue.totalPromo), 0));

  return {
    premium: parseFloat(priceObj.premium),
    tax: parseFloat(priceObj.tax),
    installments: installmentsArray,
    gf: false,
    promo: true,
    total: parseFloat(priceObj.total),
    totalPromo: totalPromoPriceByInstallmets,
    totalDiscount,
    display: true
  };
};

export const required = value => {
  const trimmedValue = value ? value.trim() : "";
  return !!trimmedValue;
};

export const surfaceValidator = value => Number(value) >= 10 && Number(value) <= 300;

export const findAddedSymbolInStr = (newStr, oldStr) => {
  const minLength = Math.min(newStr.length, oldStr.length);

  for (let i = 0; i < minLength; i++) {
    if (newStr[i] !== oldStr[i]) {
      return newStr[i];
    }
  }

  if (newStr.length > oldStr.length) {
    return newStr.slice(minLength);
  }
  if (newStr.length < oldStr.length) {
    return oldStr.slice(minLength);
  }

  return null; // Return null if no symbol was added
};

export const dateFormatValidator = value => {
  // This if is used to not overwrite the required validation
  if (value === "") {
    return true;
  }
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  return regex.test(value);
};

export const ciAgeValidator = value => {
  if (dateFormatValidator(value)) {
    const age = moment().diff(moment(value, "DD/MM/YYYY"), "years");

    return age >= 18 && age <= 69;
  }
  return false;
};

export const dateValidator = value => {
  if (value === "") {
    return true;
  }
  let isValidDate = true;

  isValidDate = dateFormatValidator(value);

  if (isValidDate && value.length === 10) {
    const day = value.slice(0, 2);
    const month = value.slice(3, 5);
    const year = value.slice(6, 10);

    // Validation for days
    const dateByYear = moment({ year: Number(year), month: Number(month) - 1, day: 1 }); // Month is 0-indexed, so subtract 1
    const maxDaysByYearAndMonth = dateByYear.daysInMonth();

    // Validation for year
    const currentYear = moment().year();

    if (Number(day) > maxDaysByYearAndMonth || Number(day) < 0) {
      isValidDate = false;
    }

    if (Number(month) > 12 || Number(month) < 0) {
      isValidDate = false;
    }

    if (Number(year) > Number(currentYear) || Number(year) < 1900) {
      isValidDate = false;
    }
  }

  return isValidDate;
};

export const dateFieldFormatter = (value, oldValue) => {
  const valArrr = value.split("/");
  const oldValArr = oldValue.split("/");

  if (value.length <= oldValue.length) {
    if (valArrr.length !== oldValArr.length && valArrr.some(subStr => subStr)) {
      return oldValue;
    }

    return value.replace("//", "");
  }

  if (value.length > 10) {
    return oldValue;
  }

  const validCharacters = /^[0-9/]*$/; // Regular expression to match numbers and '/'
  const isValueValid = validCharacters.test(value);
  const sanitizedValue = value.replace(/[^0-9/]/g, "");

  if (!isValueValid || value === "") {
    return sanitizedValue;
  }

  const hasSlashesAtPositions2And5 = [...value].every((char, index) => (char === "/" ? index === 2 || index === 5 : true));
  const addedSymbol = findAddedSymbolInStr(value, oldValue);

  if (addedSymbol === "/" && !hasSlashesAtPositions2And5) {
    return oldValue;
  }

  if (value.length === 3 && value[2] !== "/") {
    return `${value.slice(0, 2)}/${value[2]}`;
  }

  if (value.length === 6 && value[5] !== "/") {
    return `${value.slice(0, 5)}/${value[5]}`;
  }
  if (valArrr.length > 1 && valArrr[0].length > 2) {
    return oldValue;
  }

  if (valArrr.length > 1 && valArrr[1].length > 2) {
    return oldValue;
  }
  return value;
};

export const installmentNumTxt = number => `Вноска № ${number} `;

export const installmentTxt = number => {
  const str = String(number);
  switch (str) {
    case "1":
      return "1-ва";
    case "2":
      return "2-ра";
    case "3":
      return "3-та";
    case "4":
      return "4-та";
    case "5":
      return "5-та";
    case "6":
      return "6-та";
    case "7":
      return "7-ма";
    case "8":
      return "8-ма";
    case "9":
      return "9-та";
    case "10":
      return "10-та";
    case "11":
      return "11-та";
    case "12":
      return "12-та";
    default:
      return "";
  }
};

export const serviceTxt = code => {
  switch (code) {
    case "EE":
      return "Експертна оценка";
    case "TS":
      return "Доверен сервиз";
    case "OS":
      return "Официален сервиз";
    default:
      return code;
  }
};
export const deliveryWayTxt = code => {
  switch (code) {
    case 1:
      return "До адрес на клиента";
    case 2:
      return "До офис на куриерската фирма";
    default:
  }
};

export const calculateBirthDateByEgn = egn => {
  // calculate birth date by egn
  const dd = egn.substr(4, 2);
  let mm = egn.substr(2, 2);
  let yy = parseInt(egn.substr(0, 2), 10);
  if (mm > 40) {
    yy += 2000;
    mm -= 40;
  } else if (mm > 20) {
    yy += 1800;
    mm -= 20;
  } else {
    yy += 1900;
  }

  return moment()
    .year(yy)
    .month(mm - 1)
    .date(dd)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};
export const getYearsByEgn = egn => moment().diff(calculateBirthDateByEgn(egn), "years");

export const checkEGNForAdult = egn => {
  const age = getYearsByEgn(egn);
  if (age < 18) {
    return "ЕГН-то не е на пълнолетен придружител";
  }
  return true;
};
export const dateDiffInDays = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
};

export const getPageProduct = (product, screenId) => {
  if (product === "CSC+GO") {
    if (screenId === 2) {
      return "CSC";
    }
    return "GO";
  }
  return product;
};

export const checkStringInObject = (string, obj) =>
  string && typeof obj === "object" && obj !== null
    ? Object.values(obj).some(value => (typeof value === "string" && value === string) || (typeof value === "object" && checkStringInObject(string, value)))
    : false;

export const formatCity = e => {
  const isBqla = e.name === "БЯЛА" || e.name === "Бяла";
  const isVillageOrExpection = typeof e.city === "string" && e.city.slice(0, 2) === "с.";
  const formatForBqla = `${e.city}, област ${e.state}`;

  if (isBqla) {
    return formatForBqla;
  }
  if (isVillageOrExpection) {
    return `${e.city}, общ. ${e.region}`;
  }

  return e.city;
};

export const formatCityForDelivery = e => {
  const isBqla = e.name === "БЯЛА" || e.name === "Бяла";
  const region = e.region || e.regionName;
  const formatForBqla = `${e.type || ""}${e.name || ""}, област ${region || ""}`;

  if (isBqla) {
    return formatForBqla;
  }
  if (e.type === "с.") {
    return `${e.type || ""}${e.name || ""}, общ. ${e.municipality || ""}`;
  }

  return `${e.type || ""}${e.name || ""}`;
};

// Used for array of objects type config where it changes one level nested values set with {INDEX} to an ordered loop from the index
// Passing a third parameter of { configObjects, insertIndex } will insert the configObjects in the original config starting from the insertIndex
export const modifyConfig = (arr, newIndex, insertionObj = {}, subString = "INDEX") => {
  const { configObjects, insertIndex } = insertionObj;

  const modifiedArr = [...arr];

  if (configObjects && configObjects.length > 0) {
    if (Array.isArray(configObjects) && Array.isArray(insertIndex)) {
      configObjects.forEach((configObj, i) => {
        const currentIndex = insertIndex[i];
        modifiedArr.splice(currentIndex, 0, configObj);
      });
    } else {
      modifiedArr.splice(insertIndex, 0, ...configObjects);
    }
  }

  return modifiedArr.map(obj => {
    const newObj = { ...obj };

    Object.keys(newObj).forEach(key => {
      if (typeof newObj[key] === "string") {
        newObj[key] = newObj[key].replace(new RegExp(`\\{${subString}\\}`, "g"), newIndex);
      }
    });

    return newObj;
  });
};

export const removeKeysWithSubstrings = (inputObj, substringsAndPositions) =>
  Object.fromEntries(
    Object.entries(inputObj).filter(
      ([key]) =>
        !substringsAndPositions.some(
          ({ substring, position }) =>
            (position === "start" && key.startsWith(substring)) || (position === "end" && key.endsWith(substring)) || (position === "all" && key.includes(substring))
        )
    )
  );

export const categorizeAge = (border, agesArr) => {
  const [adults, children] = agesArr.reduce(
    (acc, age) => {
      acc[age >= border ? 0 : 1].push(age);
      return acc;
    },
    [[], []]
  );

  return { adults, children };
};

export const createInitFormData = txtFields =>
  txtFields.reduce(
    (formData, field) => ({
      ...formData,
      [field.name]: ""
    }),
    {}
  );

export const generateDateFromAge = ageString => {
  const agePattern = /(\d+)/; // regex pattern to match the first number in the string
  const matches = ageString.match(agePattern); // extracting the first number from the string

  if (matches && matches.length > 0) {
    const age = parseInt(matches[0]) + 2; // adding 2 to the extracted age
    const birthYear = new Date().getFullYear() - age; // calculating the birth year based on the current year and the adjusted age
    const date = new Date(birthYear, 0, 1); // creating a date object with the birth year

    // formatting the date to 'MM/DD/YYYY' format
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;

    return formattedDate;
  }
  return "Invalid age string";
};

export const calculateFV = ({ initialInvestment, monthlyAmounth, interestRate, periodInYears }) => {
  const investmentConfig = {
    1: 0.5,
    2: 0.75
  };

  const investmentTable = {};

  function calculatePercentageOfBalance(balance, percentage) {
    const percentageDecimal = percentage / 100;
    const calculatedPercentage = percentageDecimal * balance;

    return calculatedPercentage;
  }

  function calculateAmounthPerYear(currentTotal, percentageForMonthlyAmount, year) {
    const modifietMonthlyAmounth = monthlyAmounth * percentageForMonthlyAmount;
    let yearsTotal = currentTotal + modifietMonthlyAmounth;

    investmentTable[year] = [];

    for (let month = 1; month <= 12; month++) {
      const interest = calculatePercentageOfBalance(yearsTotal, interestRate);

      investmentTable[year].push({
        balance: yearsTotal.toFixed(2),
        deposit: modifietMonthlyAmounth,
        interestAmount: interest.toFixed(2)
      });

      yearsTotal += month === 12 ? interest : modifietMonthlyAmounth;
    }

    return yearsTotal;
  }

  let fvTotal = initialInvestment;

  for (let year = 1; year <= periodInYears; year++) {
    const amounthPercentage = investmentConfig[year] || 1;

    fvTotal = calculateAmounthPerYear(fvTotal, amounthPercentage, year);
  }

  return { fvTotal, investmentTable };
};

export const averageByStep1000 = (num1, num2) => {
  const average = (num1 + num2) / 2;

  const roundedAverage = Math.round(average / 1000) * 1000;

  return roundedAverage;
};

// TODO - not working for floating point numbers
export const formatNumberByHundreds = (number, roundValue) => {
  let numberString = number.toString();

  if (numberString.length > 3) {
    numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return roundValue ? Number(numberString).toFixed(roundValue) : numberString;
};

export const getPriceAccordionStatusMessage = ({ loading, error }) => {
  if (loading) {
    return "loading";
  }
  if (error) {
    return "error";
  }
  return "display";
};

export const getDisplayPrice = ({ prices }) => {
  if (!Array.isArray(prices) || prices.length === 0) {
    return null;
  }
  const objInstallemts12 = prices.find(obj => obj.installments.length === 12);

  if (objInstallemts12) {
    const { installments } = objInstallemts12;

    return Number(installments[0].premiumWithTaxAndDiscount.toFixed(2));
  }
  // In case of an insurer not having 12 installemtns to displa the monthly price
  const exampleMonthly = Number((prices[0].premiumWithTaxAndDiscountTotal / 12).toFixed(2));

  return exampleMonthly;
};

export const getRecommendedMovablePropertyLimit = ({ surface }) => {
  if (surface <= 35) return 8000;
  if (surface <= 50) return 10000;
  if (surface <= 75) return 13000;
  if (surface <= 100) return 18000;
  if (surface <= 150) return 25000;

  return 35000;
};

export const extractGraphqlQueryData = ({ data, isFetch }) => {
  const { edges } = data;

  const rawData = isFetch ? data : edges;

  const groupedData = rawData.reduce((acc, curr) => {
    const uniqueId = isFetch ? curr.unique_id : curr.node.unique_id;
    if (!acc[uniqueId]) {
      acc[uniqueId] = [];
    }
    acc[uniqueId].push(isFetch ? curr : curr.node);

    return acc;
  }, {});

  return groupedData;
};

export const compareArraysOfObjects = (array1, array2) => {
  const workingArr1 = [...array1];
  const workingArr2 = [...array2];

  if (workingArr1.length !== workingArr2.length) return false;

  workingArr1.sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));
  workingArr2.sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1));

  for (let i = 0; i < workingArr1.length; i++) {
    if (JSON.stringify(workingArr1[i]) !== JSON.stringify(workingArr2[i])) return false;
  }

  return true;
};

export const formatAddress = ({ city, street, streetNumber, block, entrance, floor, apartment }) => {
  let formattedAddress = city?.trim() ? `${city}, ` : ""; // Add city if provided
  formattedAddress += street?.trim() ? `${street}, ` : ""; // Add street if provided
  formattedAddress += streetNumber?.trim() ? `№ ${streetNumber}, ` : ""; // Add street number if provided
  formattedAddress += block?.trim() ? `бл. ${block}, ` : ""; // Add block if provided
  formattedAddress += entrance?.trim() ? `вх. ${entrance}, ` : ""; // Add entrance if provided
  formattedAddress += !!floor && floor?.trim() ? `ет. ${floor}, ` : ""; // Add floor if provided
  formattedAddress += !!apartment && apartment?.trim() ? `ап. ${apartment}` : ""; // Add apartment if provided

  // Remove trailing comma and space
  formattedAddress = formattedAddress.trim().replace(/,\s*$/, "");

  return formattedAddress;
};

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  const boleronDamains = {
    DEVELOPMENT: "localhost",
    STAGING: "staging.boleron.bg",
    PRODUCTION: "boleron.bg"
  };

  document.cookie = `${name}=${encodeURIComponent(value) || ""}${expires}; domain=.${boleronDamains[env]}; path=/; SameSite=Strict`;
};

export const shuffleArray = array => {
  const newArray = [...array]; // Create a new array to avoid modifying the original one
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

export const convertToPercentage = ({ discountRate, isFloat, fallBackPercentage = 0.1 }) => {
  const percentage = (discountRate || fallBackPercentage) * 100;

  return isFloat ? `${percentage.toFixed(2)}%` : `${percentage}%`;
};

export const getCountriesByCode = ({ countryCode }) => {
  const countryMap = {
    AL: "Албания",
    AM: "Армения",
    AU: "Австралия",
    AT: "Австрия",
    BE: "Белгия",
    BG: "България",
    BA: "Босна и Херцеговина",
    BY: "Беларус",
    CA: "Канада",
    CH: "Швейцария",
    CN: "Китай",
    CY: "Кипър",
    DE: "Германия",
    DK: "Дания",
    ES: "Испания",
    EE: "Естония",
    FI: "Финландия",
    FR: "Франция",
    GB: "Великобритания",
    GE: "Грузия",
    GR: "Гърция",
    HR: "Хърватия",
    HU: "Унгария",
    IE: "Ирландия",
    IS: "Исландия",
    IL: "Израел",
    IT: "Италия",
    LI: "Лихтенщайн",
    LT: "Литва",
    LU: "Люксенбург",
    LV: "Латвия",
    MC: "Монако",
    MD: "Молдова",
    MK: "Северна Македония",
    MT: "Малта",
    ME: "Черна гора",
    NL: "Нидерландия",
    NO: "Норвегия",
    NZ: "Нова Зеландия",
    PL: "Полша",
    PT: "Португалия",
    RO: "Румъния",
    RU: "Русия",
    RS: "Сърбия",
    SK: "Словакия",
    SI: "Словения",
    SE: "Швеция",
    TR: "Турция",
    UA: "Украйна",
    US: "САЩ",
    JP: "Япония",
    KR: "Южна Корея",
    IN: "Индия",
    MX: "Мексико",
    BR: "Бразилия",
    AR: "Аржентина",
    CL: "Чили",
    CO: "Колумбия",
    ZA: "Южна Африка",
    EG: "Египет",
    MA: "Мароко"
  };

  return countryMap[countryCode] || countryCode;
};

export const getCurrencyByCode = ({ currencyCode }) => {
  const currencyMap = {
    BGN: "лева",
    EUR: "евро",
    USD: "долара",
    GBP: "паунда",
    JPY: "йени",
    CNY: "юана",
    CHF: "франка",
    RUB: "рубли",
    CAD: "долара",
    AUD: "долара",
    NZD: "долара",
    SEK: "крони",
    NOK: "крони",
    DKK: "крони",
    PLN: "злоти",
    CZK: "крони",
    HUF: "форинта",
    RON: "лея",
    TRY: "лири",
    INR: "рупии",
    BRL: "реала",
    ZAR: "ранда",
    MXN: "песо"
  };

  return currencyMap[currencyCode] || currencyCode;
};

export const isFalsy = value =>
  value === false ||
  value === null ||
  value === undefined ||
  value === "" ||
  value === 0 ||
  Number.isNaN(value) ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === "object" && Object.keys(value).length === 0);

export const addMissingCoverageLimits = ({ arrayOfObjects, coverageLimitArr }) => {
  const predefinedCoverageLimits = coverageLimitArr;
  arrayOfObjects.forEach(obj => {
    if (obj.loading === false && Array.isArray(obj.offers)) {
      const existingCoverageLimits = obj.offers.map(offer => offer.coverageLimit);

      const missingCoverageLimits = predefinedCoverageLimits.filter(limit => !existingCoverageLimits.includes(limit.coverageLimit));

      missingCoverageLimits.forEach(missingLimit => {
        obj.offers.push({ coverageLimit: missingLimit.coverageLimit, insurer: obj.insurer });
      });
      obj.offers.sort((a, b) => a.coverageLimit - b.coverageLimit);
    }
  });
};
