import firebase from "firebase/app";
import "firebase/auth";
// import "firebase/storage";

const GATSBY_CONFIGURATION = process.env.GATSBY_CONFIGURATION || "boleron";
let config;
if (GATSBY_CONFIGURATION === "boleron") {
  config = {
    appName: "Boleron",
    apiKey: "AIzaSyAIBi31AoSZshgiHGTjwYe2TiWrl-TNrbQ",
    authDomain: "boleron-50414.firebaseapp.com",
    databaseURL: "https://boleron-50414.firebaseio.com",
    projectId: "boleron-50414",
    storageBucket: "boleron-50414.appspot.com",
    messagingSenderId: "785789926510",
    appId: "1:785789926510:web:5b6afe95e1747e2348fa7f"
    //  measurementId: "G-S0SK1DTFJG",
  };
} else if (GATSBY_CONFIGURATION === "boleron-ro") {
  config = {
    appName: "Boleron",
    apiKey: "AIzaSyAIBi31AoSZshgiHGTjwYe2TiWrl-TNrbQ",
    authDomain: "boleron-50414.firebaseapp.com",
    databaseURL: "https://boleron-50414.firebaseio.com",
    projectId: "boleron-50414",
    storageBucket: "boleron-50414.appspot.com",
    messagingSenderId: "785789926510",
    appId: "1:785789926510:web:5b6afe95e1747e2348fa7f"
    //  measurementId: "G-S0SK1DTFJG",
  };
} else if (GATSBY_CONFIGURATION === "vivacom") {
  config = {
    apiKey: "AIzaSyBYyqvqp-9IYCpCVRm9SHm_AJReosAYOcc",
    authDomain: "boleron-viva.firebaseapp.com",
    projectId: "boleron-viva",
    storageBucket: "boleron-viva.appspot.com",
    messagingSenderId: "985937544494",
    appId: "1:985937544494:web:1a47baf1b777c68ee3e6bf"
  };
}

firebase.initializeApp(config);
export default firebase;
