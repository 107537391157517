import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class CarDataStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "CarDataStore",
      properties: ["carNo", "talonNo", "vinNumber", "drivingLicense", "egn", "email", "carType", "carDamage", "carDamageInfo", "userData"],
      storage: typeof document !== "undefined" ? window.localStorage : null
    });
  }

  set(name, value) {
    this[name] = value;
  }

  carNo = "";

  talonNo = "";

  drivingLicense = "";

  egn = "";

  email = "";

  vinNumber = "";

  carType = "";

  carDamage = "";

  carDamageInfo = "";

  userData = [];
}

const carData = new CarDataStore();
export default carData;
