export default class InsurerService {
  static getInsurerName(code, product) {
    switch (code) {
      case "armeec":
        return "Армеец";
      case "allianz-ro":
        return "Allianz";
      case "euroins":
        return "Евроинс";
      case "ozk":
        return "ОЗК";
      case "dallbogg":
        return "ДаллБогг";
      case "bulstrad":
        return "Булстрад";
      case "generali":
        return "Дженерали";
      case "metlife":
        return "МетЛайф";
      case "uniqa":
        switch (product) {
          case "HLT":
          case "MTN":
          case "EXT":
            return "ЗК УНИКА ЖИВОТ АД";
          default:
            return "ЗК УНИКА АД";
        }
      case "bulins":
      case "bulinsHit":
        return "Бул Инс";
      case "dzi":
        return "ДЗИ";
      case "levins":
        return "Лев Инс";
      case "allianz":
        return product === "TRA" ? "Алианц Травел" : "Алианц";
      case "fihealth":
        return "Фи Хелт";
      case "instinct":
        return "Инстинкт";
      default:
        return "";
    }
  }

  static getInsurerDetails(product, insurerCode) {
    let insurerObj = null;
    switch (product) {
      case "TRA":
        insurerObj = {
          key: insurerCode,
          name: InsurerService.getInsurerName(insurerCode, product),
          description: ""
        };
        break;
      case "PRP":
        insurerObj = {
          key: insurerCode,
          name: InsurerService.getInsurerName(insurerCode),
          description: ""
        };
        break;
      case "MTN":
      case "EXT":
        insurerObj = {
          key: insurerCode,
          name: insurerCode === "uniqa" ? "ЗК УНИКА ЖИВОТ АД" : InsurerService.getInsurerName(insurerCode),
          description: ""
        };
        break;
      case "KID":
      case "MED":
        insurerObj = {
          key: "dzi",
          name: "ДЗИ – Животозастраховане ЕАД",
          description: ""
        };
        break;
      case "HLT":
        insurerObj = {
          key: "uniqa",
          name: "ЗК УНИКА ЖИВОТ АД",
          description: ""
        };
        break;
      case "GO":
        insurerObj = {
          key: insurerCode || "",
          name: InsurerService.getInsurerName(insurerCode),
          description: ""
        };
        break;
      case "CSC":
      case "CSC+GO":
        insurerObj = {
          key: insurerCode || "",
          name: InsurerService.getInsurerName(insurerCode),
          description: ""
        };
        break;
      default:
        insurerObj = {
          key: insurerCode || "",
          name: InsurerService.getInsurerName(insurerCode),
          description: ""
        };
        break;
    }
    return insurerObj;
  }
}
