import React from "react";
import Logo from "../../images/logo-new.svg";
import ProductsService from "../../services/ProductsService";
import TRAIcon from "../../images/boleron/landing/tra.svg";

const mainProducts = [
  {
    id: "TRA",
    title: language => ProductsService.getProductName("TRA", language),
    price: "Preturi de la 48 lei pe zi",
    link: "/ro/asigurare-de-calatorie/",
    checklist: ["Covers treatment up to €50,000", "You get the policy right away", "24/7 worldwide support"],
    successfulPurchaseMessage: "",
    insurers: [
      {
        id: "allianz-ro",
        hasPromoCode: false,
        documents: [
          {
            id: "termsUrl",
            label: "Termenii și condițiile produsului",
            url: "https://partner.magroup-webservice.com/BOL/RO/RO/CG_RO_My_Safe_Essential_2205_RO.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "IDD",
            url: "https://partner.magroup-webservice.com/BOL/RO/RO/IPID_RO_My_Safe_Essential_2205_RO.pdf"
          },
          {
            id: "privacyNoticeUrl",
            label: "Politica_de_confidentialitate - Allianz",
            url: "https://partner.magroup-webservice.com/BOL/RO/RO/RO_Politica_de_confidentialitate_RO.pdf"
          }
        ]
      }
    ]
  }
];

const onlineProducts = [];
const header = {
  logo: <Logo style={{ width: 170 }} />,
  logoText: {
    disabled: false
  },
  links: [
    { id: "home", url: "/" },
    { id: "insurances", url: "" },
    { id: "services", disabled: true },
    { id: "aboutUs", url: "/about-us", disabled: true },
    { id: "contacts", url: "/contacts", disabled: true },
    { id: "myProfile", url: "/policies", disabled: true },
    { id: "blog", url: "/blog", disabled: true },
    { id: "bottomLogo", url: "/", disabled: true }
  ],
  downloadApp: {
    disabled: true
  },
  hr: {
    disabled: true
  }
};

const marketingText = "RO - Съгласен ли си да получаваш от Boleron оферти, намаления, промоции и подаръци?";

const footer = {
  logo: <Logo className="logo-footer" />,
  logoText: {
    disabled: false
  },
  sectionMenu: {
    disabled: false
  },
  sectionMessage: {
    disabled: false
  },
  sectionSocialMedia: {
    disabled: false
  },
  phone: {
    text: "0700 500 90"
  },
  email: {
    text: "office@boleron.com"
  },
  hr: {
    disabled: true
  },
  footerText: {
    isCentered: true,
    paddingStyles: "px-2"
  }
};

const productImages = [
  {
    product: "TRA",
    image: (
      <div className="boleron-index-icon-wrapper">
        <TRAIcon className="boleron-index-icon" />
      </div>
    )
  }
];

const tenantSettings = {
  companyName: "Boleron RO",
  defaultLanguage: "ro",
  supportedLanguages: ["ro", "en"],
  defaultCurrency: "lei",
  dateFormat: "DD.MM.YYYY",
  couriers: [],
  defaultSEO: {
    title: "BOLERON-RO",
    description: "RO - Онлайн застраховки",
    lang: "ro"
  },
  showMetaDataAndSchemas: true,
  companyURL: "https://ro.boleron.bg/",
  isAppleGooglePayActive: true,
  pagesSEO: [
    {
      pageName: "/",
      title: "RO - Онлайн застраховки - бързо, лесно, сигурно",
      description: "RO - Онлайн застраховки от Boleron. Купи всички застраховки онлайн, получи полицата веднага по имейл, бързо и лесно.",
      keywords: "RO - онлайн застраховки, онлайн застраховане,мобилно приложение за застраховане, дигитално застраховане, болерон, boleron",
      canonical: "/",
      image: "product/product_card_main.jpg"
    },
    {
      pageName: "/ro/asigurare-de-calatorie/",
      title: "RO - Застраховка Пътуване в Чужбина",
      description:
        "RO -Застраховка пътуване в чужбина изцяло онлайн. Медицинска застраховка за чужбина с покритие за КОВИД 19 . Получаваш полицата веднага по имейл. Бързо и лесно.",
      keywords:
        "RO - Застраховка пътуване в чужбина, медицинска застраховка за чужбина,застраховка за пътуване в чужбина,застраховка при пътуване,застраховка пътуване, ковид застраховка,Онлайн застраховка Пътуване в чужбина, медицинска застраховка за чужбина онлайн",
      canonical: "ro/asigurare-de-calatorie",
      image: "product/product_card_tra.jpg"
    },
    {
      pageName: "/login-without-pass/",
      title: "RO - Моят профил - Вход",
      description: "RO -Достъп до Вашия профил в Boleron",
      keywords: "",
      canonical: "login-without-pass"
    },
    {
      pageName: "/documents/",
      title: "RO - Документи",
      description: "RO -Списък с документи и сертификати към онлайн платформа за застраховане - Boleron",
      keywords: "",
      canonical: "documents"
    }
  ]
};

export default {
  mainProducts,
  onlineProducts,
  productImages,
  header,
  footer,
  marketingText,
  messenger: false,
  tenantSettings,
  isCustomCarPriceEnabled: true
};
