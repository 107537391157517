import React from "react";
import Slider from "react-slick";

const VerticalCarousel = ({ arrows, slidesToShow, children }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true
  };

  return (
    <div className="slider-container">
      <Slider arrows={arrows} {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default VerticalCarousel;
