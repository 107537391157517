import _get from "lodash.get";
import axios from "axios";
import { toJS } from "mobx";
import firebase from "../config/firebase";
import { request, POST } from "./APIservice";

import {
  URL_MAGIC_LINK_PREFIX,
  URL_MARKETING_CONSENT,
  MAGIC_LINK_URL,
  URL_USER_WELCOME,
  CLIENT_VERSION,
  USER_SERVICE_URL,
  URL_USER_VERIFY,
  URL_USER_CUSTOM_TOKEN
} from "../config/env";

export default class AuthUserService {
  static unsubscribe;

  static async createUserWithEmail(email, password) {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch(error => {
        console.log(_get(error, "message"));
      });
  }

  static async setUserInfo(displayName) {
    await firebase.auth().currentUser.updateProfile({
      displayName
    });
  }

  static async updateMarketingConsent(user) {
    const token = await this.getUserToken(user);
    // always updates to true
    await axios.post(`${URL_MARKETING_CONSENT}/${user.uid}`, {}, { headers: { Authorization: `Bearer ${token}` } });
  }

  static async convertAnonymous(email, password, displayName) {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    const currentUser = await firebase.auth().currentUser;
    let linkedResponse;
    if (currentUser) {
      await currentUser
        .linkWithCredential(credential)
        .then(linkResult => {
          linkedResponse = linkResult;
        })
        .catch(error => {
          console.log(_get(error, "message"));
        });
      await firebase.auth().currentUser.updateProfile({
        displayName
      });
    }
    return _get(linkedResponse, "user");
  }

  static async loginCustom(customToken) {
    try {
      return await firebase.auth().signInWithCustomToken(customToken);
    } catch (error) {
      console.log(error);
    }
  }

  static getCurrentUser = async () => firebase.auth().currentUser;

  static subscribeAuthChange(callback) {
    this.c = firebase.auth().onAuthStateChanged(callback);
  }

  static unsubscribeAuthChange() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  static async logOut() {
    await firebase
      .auth()
      .signOut()
      .catch(error => {
        throw new Error(error.message);
      });
  }

  static async sendPasswordResetEmail(email) {
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch(error => {
        throw error.message;
      });
  }

  static isSignInWithEmailLink(href) {
    return firebase.auth().isSignInWithEmailLink(href);
  }

  static async signInWithEmailLink(email, href) {
    return firebase.auth().signInWithEmailLink(email, href);
  }

  static async loginWithEmail(email, password) {
    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        throw new Error(error.message);
      });
    return res;
  }

  static async getUserToken(user) {
    const target = toJS(user);
    const token1 = _get(user, "getIdToken") ? await user.getIdToken() : null;
    let token2;
    if (!token1) {
      const expirationTime = _get(target, "stsTokenManager.expirationTime");
      if (expirationTime < Date.now()) {
        const refreshToken = _get(target, "stsTokenManager.refreshToken");
        const response = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${_get(target, "apiKey")}`, {
          grant_type: "refresh_token",
          refresh_token: refreshToken
        });
        token2 = _get(response, "data.access_token");
      } else {
        token2 = _get(target, "stsTokenManager.accessToken", null);
      }
    }
    return token1 || token2;
  }

  static async loginAnonymous() {
    await firebase
      .auth()
      .signInAnonymously()
      .catch(error => {
        throw new Error(error.message); // todo някой път хвърля грешка от firebase
      });
  }

  static async sendEmailLinkForAuthentication(email, redirectPath, source, user) {
    const url = `${URL_MAGIC_LINK_PREFIX}/login-with-email-link${redirectPath ? `?redirectPath=${redirectPath}` : ""}`;
    const token = await this.getUserToken(user);
    try {
      await request(POST, MAGIC_LINK_URL, { url: encodeURI(url), userEmail: email, source }, null, token, true);
      window.localStorage.setItem("emailForSignIn", email);
    } catch (e) {
      const msg = _get(e, "response.data", e.message);

      throw new Error(msg);
    }
  }

  static async userCreateNewProfile(user, password, token) {
    await axios.post(
      URL_USER_WELCOME,
      {
        user,
        password,
        version: CLIENT_VERSION
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

  static async getCustomToken(uid, token) {
    try {
      if (uid) {
        const response = await axios.get(
          `${URL_USER_CUSTOM_TOKEN}/${uid}`
          //  ,{ headers: { Authorization: `Bearer ${token}` }}
          // TODO - USE CURRENT TOKEN FOR SECURITY
        );

        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return "";
  }

  static async getUserProfileData(uid, token) {
    const response = await axios.get(`${USER_SERVICE_URL}/${uid}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  }

  static async updateUserProfile(user, token, type) {
    await axios.post(
      USER_SERVICE_URL,
      {
        user,
        type // todo като добавим в профила type трявба да е "profile"
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

  static async userProfileExists({ email, user }) {
    const token = await this.getUserToken(user);

    const response = await axios.get(`${URL_USER_VERIFY}/${email}`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    const data = _get(response, "data", {});

    return data;
  }
}
