import { default as boleron } from "./boleron";
import { default as vivacom } from "./vivacom";
import { default as boleronro } from "./boleron-ro";

import { GATSBY_CONFIGURATION } from "../activeProducts";

let urls = boleron;
if (GATSBY_CONFIGURATION === "vivacom") {
  const objWithoutUndefinedValues = Object.fromEntries(Object.entries(vivacom).filter(([k, v]) => v !== undefined));
  urls = Object.assign(boleron, objWithoutUndefinedValues);
}
if (GATSBY_CONFIGURATION === "boleron-ro") {
  const objWithoutUndefinedValues = Object.fromEntries(Object.entries(boleronro).filter(([k, v]) => v !== undefined));
  urls = Object.assign(boleron, objWithoutUndefinedValues);
}
export default { ...urls };
