export const env = process.env.GATSBY_ENV || "DEVELOPMENT";

let urlPrefix;
let urlFibank;
let magicLinkRedirect;
let webSocketPrefix;
let apiURL;
let chatGPTWebSocket;
if (env === "DEVELOPMENT") {
  urlPrefix = "http://localhost:1337/ro"; // "http://10.0.2.2:1337/"; for android app
  webSocketPrefix = "ws://localhost:1337/ro";
  urlFibank = "https://mdpay-test.fibank.bg/ecomm_v2/ClientHandler";
  magicLinkRedirect = "http://localhost:8000";
  chatGPTWebSocket = "http://localhost:1333/development/universal";
} else if (env === "PRODUCTION") {
  urlPrefix = "https://api.boleron.bg:443/ro";
  webSocketPrefix = "wss://api.boleron.bg/ro";
  urlFibank = "https://mdpay.fibank.bg/ecomm_v2/ClientHandler";
  magicLinkRedirect = "https://boleron.bg";
  chatGPTWebSocket = "https://boleron-chatbot-gpt.azurewebsites.net/production/universal";
} else if (env === "STAGING") {
  // staging
  urlPrefix = "https://boleron-devapi.azurewebsites.net/ro";
  webSocketPrefix = "wss://boleron-devapi.azurewebsites.net/ro";
  urlFibank = "https://mdpay-test.fibank.bg/ecomm_v2/ClientHandler";
  magicLinkRedirect = "https://ro.boleron.bg";
  chatGPTWebSocket = "https://boleron-chatbot-gpt.azurewebsites.net/staging/universal";
}

export default {
  urlPrefix,
  urlFibank,
  magicLinkRedirect,
  webSocketPrefix,
  apiURL,
  chatGPTWebSocket
};
