import React, { useState } from "react";
import { I18nextContext, Link, useTranslation } from "gatsby-plugin-react-i18next";
// import { Link as GatsbyLink } from "gatsby"
import { navigate } from "gatsby";
import _get from "lodash.get";
import Collapsible from "@common/Collapsible";
import DoubleArrowIcon from "@icons/double-arrow.svg";
import { LanguageSwitcherMobile } from "./LanguageSwitchers";
import { Colors } from "../../styles/colors";
import { getHeaderLink, showHeaderLink } from "../../config/activeProducts/helpers";
import activeProducts from "../../config/activeProducts";
import ProductsService from "../../services/ProductsService";
import Logo from "../../../static/png/vivacom_logo_positive.png";

const MobileContent = ({ auth, containsLanguageSwitcher, ...langSwitcherProps }) => {
  const { t } = useTranslation();
  const [isMyProfileOpen, setMyProfileOpen] = useState(false);
  const [isInsuranceOpen, setInsuranceOpen] = useState(true);
  const [isOnlineServicesOpen, setOnlineServicesOpen] = useState(false);
  const onlineProducts = activeProducts?.onlineProducts;
  const mainProducts = activeProducts?.mainProducts;
  const languageContext = React.useContext(I18nextContext);
  const language = languageContext?.language;

  return (
    <>
      <div className="body-navbar-end">
        {containsLanguageSwitcher && <LanguageSwitcherMobile {...langSwitcherProps} navbarWorkflowPadding={true} />}
        <Link className="navbar-item-body-navbar top-line" activeClassName="active" to="/">
          {t("Home", "Начало")}
        </Link>

        {showHeaderLink("myProfile") && (
          <div
            onClick={() => {
              setMyProfileOpen(!isMyProfileOpen);
            }}
            className="panel-block top-line mb-0 px-0 py-0"
          >
            <Link
              className="navbar-item-body-navbar top-line"
              activeClassName="active"
              style={{ border: "none" }}
              to={_get(auth, "isAuthenticated", false) ? "/my-profile-page" : "/policies"}
            >
              {("My profile", "Моят профил")}
            </Link>
          </div>
        )}

        <div onClick={() => setInsuranceOpen(!isInsuranceOpen)} className={`panel-block top-line mb-0 py-1 ${isInsuranceOpen ? "panel-block-background" : ""}`}>
          <Collapsible title={t("Products", "Застраховки")} navbar={true} isOpened={true}>
            {mainProducts.map(product =>
              !product?.disabled ? (
                <div key={product?.id} className="panel-block-content" style={{ alignItems: "center" }}>
                  {ProductsService.getProductsMenuIcons(product?.id)}
                  <Link className="navbar-item-body-navbar" activeClassName="active" to={product?.link}>
                    {product?.title(language)}
                  </Link>
                </div>
              ) : null
            )}
          </Collapsible>
        </div>

        {!!onlineProducts?.length && (
          <div onClick={() => setOnlineServicesOpen(!isOnlineServicesOpen)} className={`panel-block top-line mb-0 py-1 ${isOnlineServicesOpen ? "panel-block-background" : ""}`}>
            <Collapsible title={t("Online services", "Онлайн услуги")} navbar={true}>
              {onlineProducts.map(product =>
                !product?.disabled ? (
                  <div key={product?.id} className="panel-block-content">
                    <DoubleArrowIcon
                      className="mr-2"
                      style={{
                        width: 12,
                        height: "auto",
                        backgroundRepeat: "no-repeat"
                      }}
                    />
                    {product?.link.startsWith("http") ? (
                      <a className="navbar-item-body-navbar" rel="noreferrer noopener" target="_blank" href={product?.link}>
                        {product?.title(language)}
                      </a>
                    ) : (
                      <Link className="navbar-item-body-navbar" activeClassName="active" to={product?.link}>
                        {product?.title(language)}
                      </Link>
                    )}
                  </div>
                ) : null
              )}
            </Collapsible>
          </div>
        )}

        {
          // TODO - find a better way for different menu names according to tenant -->
        }

        {showHeaderLink("aboutUs") && (
          <Link className="navbar-item-body-navbar top-line" activeClassName="active" style={{ color: Colors.GRAY }} to="/documents">
            {t("Documents", "Документи")}
          </Link>
        )}
        {!showHeaderLink("aboutUs") && (
          <Link className="navbar-item-body-navbar top-line" activeClassName="active" to="/documents">
            {"Informații utile"}
          </Link>
        )}
        {showHeaderLink("aboutUs") && (
          <Link className="navbar-item-body-navbar top-line" activeClassName="active" style={{ color: Colors.GRAY }} to={getHeaderLink("aboutUs")?.url}>
            {t("AboutUs", "За Нас")}
          </Link>
        )}
        {showHeaderLink("contacts") && (
          <Link className="navbar-item-body-navbar top-line" activeClassName="active" style={{ color: Colors.GRAY }} to={getHeaderLink("contacts")?.url}>
            {t("Contacts", "Контакти")}
          </Link>
        )}
        {showHeaderLink("blog") && (
          <Link className="navbar-item-body-navbar top-line" activeClassName="active" style={{ color: Colors.GRAY }} to={getHeaderLink("blog")?.url}>
            {t("Blog", "Блог")}
          </Link>
        )}
      </div>
      {showHeaderLink("bottomLogo") && (
        <section className="mobile-menu-bottom-logo">
          <img src={Logo} alt="logo" />
        </section>
      )}
    </>
  );
};

export default MobileContent;
