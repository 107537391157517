import React from "react";
import Logo from "../../images/logo-new.svg";
import ProductsService from "../../services/ProductsService";
import MTNIcon from "../../images/boleron/landing/mtn.svg";
import EXTIcon from "../../images/boleron/landing/ext.svg";
import HLTIcon from "../../images/boleron/landing/hlt.svg";
import LFEIcon from "../../images/boleron/landing/lfe.svg";
import LFIIcon from "../../images/boleron/landing/lfi.svg";
import CIIcon from "../../images/boleron/landing/ci.svg";
import HWPIcon from "../../images/boleron/landing/hwp.svg";
import GOIcon from "../../images/boleron/landing/go.svg";
import CSCIcon from "../../images/boleron/landing/csc.svg";
import TRAIcon from "../../images/boleron/landing/tra.svg";
import PRPIcon from "../../images/boleron/landing/prp.svg";
import CSCGOIcon from "../../images/boleron/landing/cscgo.svg";
import GLOIcon from "../../images/boleron/landing/glo.svg";
import VigIcon from "../../images/boleron/landing/vig.svg";
import ChrIcon from "../../images/boleron/landing/crh.svg";

const mainProducts = [
  {
    id: "CSC+GO",
    disabled: false, // todo to remove it
    title: language => ProductsService.getProductName("CSC+GO", language),
    price: "Compare prices and save up to 50%",
    link: "/kasko-plus-grazhdanska/",
    checklist: ["Combine and save", "Extra discount", "Free Shipping"],
    couriers: ["speedy", "econt"],
    successfulPurchaseMessage: "",
    offlineOffer: true,
    insurers: [
      {
        id: "allianz",
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_ALLIANZ_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_OU_ALLIANZ.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_ALLIANZ.pdf"
          }
        ]
      },
      {
        id: "armeec",
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_ARMEEC.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_ARMEEC.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_ARMEEC_OU.pdf"
          }
        ]
      },
      {
        id: "bulins",
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_BULINS.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_BULINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULINS_OU.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_OU.PDF"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/BULSTRAD_PDOG.pdf"
          }
        ]
      },
      {
        id: "dzi",
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_DZI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_DZI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_DZI_OU.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_OU.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          }
        ]
      },
      {
        id: "generali",
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_GENERALI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_GENERALI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_GENERALI_OU.pdf"
          }
        ]
      },
      {
        id: "levins",
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_OU.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "GO",
    title: language => ProductsService.getProductName("GO", language),
    price: "Compare prices and save",
    link: "/grazhdanska-otgovornost-online/",
    checklist: ["Choice of 10 insurers", "See prices in just 30 seconds", "Free Shipping"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "allianz",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          }
        ] // todo за армеец
      },
      {
        id: "armeec",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          }
        ] // todo to check helpUrl, euDocumentUrl
      },
      {
        id: "bulins",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_BULINS_A3.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrlGO_BULSTRAD",
            label: "ЗЕАД Булстрад Виена Иншурънс Груп",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_BULSTRAD_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_UNIQA",
            label: "ЗК УНИКА АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_UNIQA_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_EUROINS",
            label: "ЗД Евроинс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_EUROINS_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_OZK",
            label: "ЗАД ОЗК Застраховане АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_OZK_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_BULINS",
            label: "ЗД Булинс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_BULINS_A3.pdf"
          },

          {
            id: "euDocumentUrlGO_DALBOGG",
            label: "ЗАД Далл Богг: Живот и Здраве АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DALBOGG_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_GENERALI",
            label: "Дженерали Застраховане АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_GENERALI_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_DZI",
            label: "ДЗИ - Общо застраховане ЕАД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DZI_A3.pdf"
          },
          {
            id: "euDocumentUrlCSC_OU_ALLIANZ",
            label: "ЗАД Алианц България",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_OU_ALLIANZ.pdf"
          },
          {
            id: "euDocumentUrlGO_ARMEEC",
            label: "ЗАД Армеец АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_ARMEEC_A3.pdf"
          },
          {
            id: "euDocumentUrlGO_LEVINS",
            name: "ЗК Лев Инс АД",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_LEVINS_A3.pdf"
          }
        ]
      },
      {
        id: "dallbogg",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DALLBOGG_A3.pdf"
          }
        ]
      },
      {
        id: "dzi",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_DZI_A3.pdf"
          }
        ]
      },
      {
        id: "euroins",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_EUROINS_A3.pdf"
          }
        ]
      },
      {
        id: "generali",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_GENERALI_A3.pdf"
          }
        ]
      },
      {
        id: "levins",
        paymentModes: ["1", "2"],
        documents: [
          { id: "helpUrl", label: "", url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf" },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_LEVINS_A3.pdf"
          }
        ]
      },
      {
        id: "ozk",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_OZK_A3.pdf"
          }
        ]
      },
      {
        id: "uniqa",
        paymentModes: ["1", "2"],
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_HELP_20200330.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/GO_UNIQA_A3.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "CSC",
    title: language => ProductsService.getProductName("CSC", language),
    price: "Compare prices and save up to 30%",
    link: "/kasko-kalkulator/",
    // Сравни оферти
    checklist: ["Choice of 8 insurers", "Automatic market valuation", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    offlineOffer: true,
    insurers: [
      {
        id: "dzi",
        hasPromoCode: true,
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_DZI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_DZI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_DZI_OU.pdf"
          }
        ]
      },
      {
        id: "armeec",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_ARMEEC.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_ARMEEC.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_ARMEEC_OU.pdf"
          }
        ]
      },
      {
        id: "bulins",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_BULINS.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_BULINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULINS_OU.pdf"
          }
        ]
      },
      {
        id: "bulinsHit",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_BULINS.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_BULINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULINS_OU.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_BULSTRAD_OU.PDF"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/BULSTRAD_PDOG.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_EUROINS_OU.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          }
        ]
      },
      {
        id: "generali",
        hasPromoCode: true,
        hasPrice: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_HELP_GENERALI.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_A3_GENERALI.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_GENERALI_OU.pdf"
          }
        ]
      },
      {
        id: "levins",
        hasPrice: true,
        hasPromoCode: true,
        documents: [
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_HELP.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/CSC_LEVINS_OU.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "TRA",
    title: language => ProductsService.getProductName("TRA", language),
    price: "Prices from BGN 2.00 per day",
    link: "/zastrahovka-patuvane-v-chuzhbina/",
    checklist: ["Covers treatment up to €50,000", "You get the policy right away", "24/7 worldwide support"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "levins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_LEVINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_LEVINS.pdf"
          },
          { id: "predogUrl", label: "Преддоговорна информация", url: "#" },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_LEVINS.pdf"
          }
        ]
      },
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_UNIQA.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_UNIQA.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      },
      {
        id: "allianz",
        hasPromoCode: true,
        documents: [
          {
            id: "termsUrl",
            label: "Общи условия на продукт при покритие до 10 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "termsUrl10000",
            label: "Общи условия на продукт при покритие до 10 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "termsUrl30000",
            label: "Общи условия на продукт при покритие до 30 000 евро",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_ALLIANZ_CLASSIC.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "euDocumentUrl10000",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_BALANCE.pdf"
          },
          {
            id: "euDocumentUrl30000",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_ALLIANZ_CLASSIC.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_ALLIANZ_PREDOG.pdf"
          },
          {
            id: "privacyNoticeUrl",
            label: "Съобщение за поверителността на личните данни",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_ALLIANZ_PRIVACY_NOTECE_2020.pdf"
          },
          {
            id: "helpUrl",
            label: "Полезна информация при възникване на застрахователно събитие",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_ALLIANZ.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_BULSTRAD.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_BULSTRAD.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_BULSTRAD.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_BULSTRAD.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_EUROINS.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_EUROINS.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_EUROINS.pdf"
          }
        ]
      },
      {
        id: "instinct",
        //    disabled: true,
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_A3_INSTINCT.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_OU_INSTINCT.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_INSTINCT.pdf"
          },
          {
            id: "userInsuranceInfo",
            label: "Информация за ползвателя на застрахователни услуги",
            url: "https://devdiag558.blob.core.windows.net/documents/INFO_USER_INS.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/INSTINCT_UVP.pdf "
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/TRA_HELP_INSTINCT.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "PRP",
    title: language => ProductsService.getProductName("PRP", language),
    price: "Prices from BGN 10 per month",
    link: "/zastrahovka-imushtestvo/",
    checklist: ["Fire, flood, earthquake", "Automatic monthly payment", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_A3_20191408.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_OU_20191408.pdf"
          },
          {
            id: "additionalTermsUrl",
            label: "Специални условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_SU_20191408.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_HELP_UNIQA.pdf"
          },
          {
            id: "assistanceTermsUrl",
            label: 'Клауза "Домашен асистанс"',
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_UNIQA_HOME_ASSISTANT.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          },
          {
            id: "goTermsUrl",
            label: 'ОУ "Обща Гражданска Отговорност"',
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_UNIQA_OU_OGO.pdf"
          } // само ако е избран чека в допълнителни покрития
        ]
      },
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_BULSTRAD_BONUS_DOM+_A3.pdf "
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: " https://devdiag558.blob.core.windows.net/documents/PRP_BULSTRAD_BONUS_DOM+_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/PRP_HELP_BULSTRAD.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "MTN",
    title: language => ProductsService.getProductName("MTN", language),
    price: "Prices from BGN 4 per month",
    link: "/planinska-zastrahovka/",
    checklist: ["Rescue and medical expenses", "Completely online, paperless", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_A3_20191408.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_OU_20191408.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_LIFE_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_HELP_EUROINS.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/PREDOG_TRA_EUROINS.pdf"
          }
        ]
      },
      {
        id: "bulstrad",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_BULSTRAD_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_BULSTRAD_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при застрахователно събитие",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_HELP_BULSTRAD.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/BULSTRAD_PDOG.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "EXT",
    title: language => ProductsService.getProductName("EXT", language),
    price: "Mountain biking, paragliding, off-piste skiing",
    link: "/zastrahovka-ekstremni-sportove/",
    checklist: ["Rescue and medical expenses", "Completely online, paperless", "You get the policy right away"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_A3_20191408.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_OU_20191408.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_LIFE_PREDOG.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/EXT_HELP_UNIQA.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          }
        ]
      },
      {
        id: "euroins",
        hasPromoCode: true,
        documents: [
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_A3.pdf"
          },
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/MTN_EUROINS_OU.pdf"
          },
          {
            id: "helpUrl",
            label: "Помощ при щета",
            url: "https://devdiag558.blob.core.windows.net/documents/EXT_HELP_EUROINS.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "HWP",
    disabled: false,
    title: language => ProductsService.getProductName("HWP", language),
    price: "for treatment of serious illnesses abroad - price BGN 81.63 per month",
    link: "/best-doctors/",
    contentProduct: "",
    checklist: ["The best hospitals", "100,000 for medicine", "Up to 4,000,000 for treatment"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [{ id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/HLT_METLIFE_TERM_OU.pdf" }]
      }
    ]
  },
  {
    id: "LFE",
    disabled: false,
    title: language => ProductsService.getProductName("LFE", language),
    price: "Prices from BGN 50 per month",
    link: "/zastrahovka-jivot/",
    contentProduct: "",
    checklist: ["Up to 400,000 Euro", "No medical examination", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [
          { id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/LIFE_METLIFE_TERM_OU.pdf" },
          { id: "helpUrl", label: "Полезна информация при възникване на застрахователно събитие", url: "https://devdiag558.blob.core.windows.net/documents/LIFE_METLIFE_HELP.pdf" }
        ]
      }
    ]
  },
  {
    id: "LFI",
    disabled: false,
    title: language => ProductsService.getProductName("LFI", language),
    price: "Annual return up to 12%",
    link: "/zastrahovka-zhivot-investitsionen-fond/",
    contentProduct: "",
    checklist: ["Up to 350,000 BGN Life insurance", "No medical examination", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [
          { id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/LFI_METLIFE_OU.pdf" },
          { id: "helpUrl", label: "Полезна информация при възникване на застрахователно събитие", url: "https://devdiag558.blob.core.windows.net/documents/LIFE_METLIFE_HELP.pdf" },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/LFI_METLIFE_INFODOC.pdf"
          }
        ]
      }
    ]
  },
  {
    id: "CI",
    disabled: false,
    title: language => ProductsService.getProductName("CI", language),
    price: "Prices from BGN 50 per month for worker",
    link: "/firmena-zastrahovka/",
    contentProduct: "",
    checklist: ["100 000 лв. застраховка Живот", "50 000 лв. при тежко заболяване", "100 лв. на ден при хоспитализация"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "metlife",
        hasPromoCode: true,
        documents: [{ id: "termsUrl", label: "Общи условия на продукта", url: "https://devdiag558.blob.core.windows.net/documents/CI_METLIFE_OU.pdf" }]
      }
    ]
  },
  {
    id: "HLT",
    title: language => ProductsService.getProductName("HLT", language),
    price: "prices from BGN 7.96 per month",
    link: "/zastrahovka-bolnichen-prestoi/",
    checklist: ["Hospital stay costs", "No medical examination", "Completely online, paperless"],
    successfulPurchaseMessage: "",
    couriers: ["speedy", "econt"],
    insurers: [
      {
        id: "uniqa",
        hasPromoCode: true,
        documents: [
          {
            id: "termsUrl",
            label: "Общи условия на продукта",
            url: "https://devdiag558.blob.core.windows.net/documents/BOL_OU_20192409.pdf"
          },
          {
            id: "euDocumentUrl",
            label: "Информационен документ",
            url: "https://devdiag558.blob.core.windows.net/documents/BOL_A3_20191104.pdf"
          },
          {
            id: "predogUrl",
            label: "Преддоговорна информация",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_LIFE_PREDOG.pdf"
          },
          {
            id: "uvpUrl",
            label: "Уведомление за поверителност",
            url: "https://devdiag558.blob.core.windows.net/documents/UNIQA_UVP.pdf"
          },
          {
            id: "schemaUrl",
            label: "Схема на УНИКА за категориите операции",
            url: "https://devdiag558.blob.core.windows.net/documents/BOL_OP_SCHEMA.pdf"
          }
        ]
      }
    ]
  }
];

const onlineProducts = [
  {
    id: "GLO",
    title: language => ProductsService.getProductName("GLO", language),
    price: " ",
    link: "/proverka-globi-kat-grazhdanska/",
    checklist: ["Civil Liability", "Vignette and fines from cat", "Technical Review"],
    callToAction: "Check"
  },
  {
    id: "KLE",
    disabled: true,
    title: language => ProductsService.getProductName("KLE", language),
    price: "Жилищни и фирмени кредити",
    link: "http://btconsult.bg?utm_source=boleron&utm_medium=affiliate&utm_campaign=boleron_first_page",
    checklist: ["Изгодни условия", "Оферти от всички банки", "Безплатна услуга"],
    callToAction: "Get the Offer"
  },
  {
    id: "VIG",
    title: language => ProductsService.getProductName("VIG", language),
    price: "",
    contentProduct: "With us you can buy your electronic vignette quickly and easily with just a few clicks.",
    link: "/workflow/init-workflow?product=VIG",
    callToAction: ""
  },
  {
    id: "CRH",
    title: language => ProductsService.getProductName("CRH", language),
    price: "",
    contentProduct: "Check the history of your vehicle by entering the VIN number or receipt number",
    link: "/vehicle-history",
    callToAction: "Check"
  }
];
const header = {
  logo: <Logo style={{ width: 170 }} />,
  logoText: {
    disabled: false
  },
  links: [
    { id: "home", url: "/" },
    { id: "insurances", url: "" },
    { id: "services", disabled: false },
    { id: "aboutUs", url: "/about-us", disabled: false },
    { id: "contacts", url: "/contacts", disabled: false },
    { id: "myProfile", url: "/policies", disabled: false },
    { id: "blog", url: "/blog", disabled: false },
    { id: "bottomLogo", url: "/", disabled: true }
  ],
  downloadApp: {
    disabled: false
  },
  hr: {
    disabled: true
  }
};

const marketingText = "Съгласен ли си да получаваш от Boleron оферти, намаления, промоции и подаръци?";

const footer = {
  logo: <Logo className="logo-footer" />,
  logoText: {
    disabled: false
  },
  sectionMenu: {
    disabled: false
  },
  sectionMessage: {
    disabled: false
  },
  sectionSocialMedia: {
    disabled: false
  },
  phone: {
    text: "0700 500 90"
  },
  email: {
    text: "office@boleron.com"
  },
  hr: {
    disabled: true
  },
  footerText: {
    isCentered: true,
    paddingStyles: "px-2"
  }
};

const productImages = [
  {
    product: "CSC+GO",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CSCGOIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "GO",
    image: (
      <div className="boleron-index-icon-wrapper">
        <GOIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CSC",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CSCIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "TRA",
    image: (
      <div className="boleron-index-icon-wrapper">
        <TRAIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "PRP",
    image: (
      <div className="boleron-index-icon-wrapper">
        <PRPIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "MTN",
    image: (
      <div className="boleron-index-icon-wrapper">
        <MTNIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "EXT",
    image: (
      <div className="boleron-index-icon-wrapper">
        <EXTIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "LFE",
    image: (
      <div className="boleron-index-icon-wrapper">
        <LFEIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "LFI",
    image: (
      <div className="boleron-index-icon-wrapper">
        <LFIIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "HWP",
    image: (
      <div className="boleron-index-icon-wrapper">
        <HWPIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "HLT",
    image: (
      <div className="boleron-index-icon-wrapper">
        <HLTIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CI",
    image: (
      <div className="boleron-index-icon-wrapper">
        <CIIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "GLO",
    image: (
      <div className="boleron-index-icon-wrapper">
        <GLOIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "VIG",
    image: (
      <div className="boleron-index-icon-wrapper">
        <VigIcon className="boleron-index-icon" />
      </div>
    )
  },
  {
    product: "CRH",
    image: (
      <div className="boleron-index-icon-wrapper">
        <ChrIcon className="boleron-index-icon" />
      </div>
    )
  }
];

const tenantSettings = {
  companyName: "Boleron",
  couriers: ["speedy", "econt"],
  defaultSEO: {
    title: "BOLERON",
    description: "Онлайн застраховки",
    lang: "bg"
  },
  showMetaDataAndSchemas: true,
  companyURL: "https://boleron.bg/",
  isAppleGooglePayActive: true,
  pagesSEO: [
    {
      pageName: "/",
      title: "Онлайн застраховки - бързо, лесно, сигурно",
      description: "Онлайн застраховки от Boleron. Купи всички застраховки онлайн, получи полицата веднага по имейл, бързо и лесно.",
      keywords: "онлайн застраховки, онлайн застраховане,мобилно приложение за застраховане, дигитално застраховане, болерон, boleron",
      canonical: "/",
      image: "product/product_card_main.jpg"
    },
    {
      pageName: "/grazhdanska-otgovornost-online/",
      title: "Гражданска отговорност онлайн",
      description:
        "Сравнете цените на застрахователите и поръчайте гражданска отговорност онлайн. Безплатна доставка на стикер и зелена карта. Изберете най-ниските цени и спестете до 50%.",
      keywords:
        "гражданска отговорност,гражданска отговорност цена,застраховка гражданска отговорност, гражданска отговорност калкулатор, онлайн калкулатор гражданска отговорност,Гражданска отговорност онлайн, гражданска отговорност цени, гражданска отговорност най-ниски цени, гражданска отговорност на автомобилистите, ГО, онлайн ГО",
      canonical: "grazhdanska-otgovornost-online",
      image: "product/product_card_go.jpg"
    },
    {
      pageName: "/grazhdanska-otgovornost-vnoski/",
      title: "Гражданска отговорност - плащане на вноски",
      description: "Платете онлайн вашата предстояща вноска по застраховка Гражданска отговорност. Бързо и лесно, безплатна доставка на стикер и зелена карта.",
      keywords:
        "Гражданска отговорност онлайн вноски, Застраховка гражданска отговорност вноски, гражданска отговорност на автомобилистите вноски, ГО вноска, онлайн ГО вноски, вноска по гражданска отговорност",
      canonical: "grazhdanska-otgovornost-vnoski",
      image: "product/product_card_go.jpg"
    },
    {
      pageName: "/grazhdanska-otgovornost/",
      title: "Гражданска отговорност. Сравни цените и спести до 50%.",
      description: "Гражданска отговорност. Сравни цените и спести до 50%.",
      keywords: "Гражданска отговорност",
      canonical: "grazhdanska-otgovornost",
      image: "product/product_card_go.jpg"
    },
    {
      pageName: "/kasko-kalkulator/",
      title: "Каско калкулатор – спести до 30%",
      description:
        "Каско калкулатор, сравни цените и спести до 30%. Купи онлайн автокаско и получи полицата веднага по имейл. Сравни цените и условията на Булстрад, Левинс, Алианц, ДЗИ, Армеец, Булинс и Дженерали.",
      keywords:
        "каско калкулатор,автокаско калкулатор,каско,автокаско,калкулатор каско,булстрад каско,каско цена, автокаско цени, kasko, застраховка каско, левинс каско, застраховка каско калкулатор,пълно автокаско цена,kasko kalkulator,,автокаско онлайн,автокаско подновяване,каско подновяване,каско булстрад,каско евроинс,каско алианц,каско дзи, каско армеец,каско левинс,онлайн каско",
      canonical: "kasko-kalkulator",
      image: "/product/product_card_csc.jpg"
    },
    {
      pageName: "/kasko-plus-grazhdanska/",
      title: "Каско + Гражданска",
      description: " Каско + Гражданска. Вземи комбиниран продукт и спести до 50%. Онлайн калкулатор за гражданска и каско. Сравни цените и купи онлайн бързо и лесно",
      keywords: " каско + гражданска, каско и гражданска, гражданска отговорност и каско, автокаско и гражданска, гражданска отговорност и каско калкулатор",
      canonical: "kasko-plus-grazhdanska",
      image: "product/product_card_go_plus_csc"
    },
    {
      pageName: "/planinska-zastrahovka/",
      title: "Планинска застраховка",
      description: "Планинска застраховка онлайн. Получи полица веднага по имейл. Бързо и лесно.",
      keywords:
        "планинска застраховка, планинска застраховка онлайн, планинска застраховка цена, ски застраховка, застраховка за планина,ПСС застраховка,уника планинска застраховка,застраховка за скиори, къде да си направя планинска застраховка, онлайн планинска застраховка",
      canonical: "planinska-zastrahovka",
      image: "product/product_card_mtn.jpg"
    },
    {
      pageName: "/zastrahovka-ekstremni-sportove/",
      title: "Застраховка екстремни спортове",
      description: "Застраховка екстремни спортове, най-добрата защита за практикуващи екстремен спорт в планината. Получи полица веднага по имейл. Бързо и лесно.",
      keywords: "Застраховка екстремни спортове, Застраховка екстремни спортове уника, екстремни спортове, застраховка извън пистите",
      canonical: "zastrahovka-ekstremni-sportove",
      image: "product/product_card_ext.jpg"
    },
    {
      pageName: "/zastrahovka-imushtestvo/",
      title: "Застраховка Имущество",
      description:
        "Застраховка имущество. Онлайн застраховка имущество, апартамент или къща. Не е необходим оглед. Получаваш полица веднага по имейл. Купи онлайн застраховка имущество.",
      keywords:
        "застраховка имущество, застраховка на жилище, застраховка за дома, застраховка имот,застраховка на апартамент, застраховка на къща, калкулатор застраховка имущество",
      canonical: "zastrahovka-imushtestvo",
      image: "product/product_card_prp.jpg"
    },
    {
      pageName: "/zastrahovka-patuvane-v-chuzhbina/",
      title: "Застраховка Пътуване в Чужбина",
      description: "Застраховка пътуване в чужбина изцяло онлайн. Медицинска застраховка за чужбина с покритие за КОВИД 19 . Получаваш полицата веднага по имейл. Бързо и лесно.",
      keywords:
        "Застраховка пътуване в чужбина, медицинска застраховка за чужбина,застраховка за пътуване в чужбина,застраховка при пътуване,застраховка пътуване, ковид застраховка,Онлайн застраховка Пътуване в чужбина, медицинска застраховка за чужбина онлайн",
      canonical: "zastrahovka-patuvane-v-chuzhbina",
      image: "product/product_card_tra.jpg"
    },
    {
      pageName: "/login-without-pass/",
      title: "Моят профил - Вход",
      description: "Достъп до Вашия профил в Boleron",
      keywords: "",
      canonical: "login-without-pass"
    },
    {
      pageName: "/documents/",
      title: "Документи",
      description: "Списък с документи и сертификати към онлайн платформа за застраховане - Boleron",
      keywords: "",
      canonical: "documents"
    }
  ]
};

export default {
  mainProducts,
  onlineProducts,
  productImages,
  header,
  footer,
  marketingText,
  messenger: false,
  tenantSettings,
  isCustomCarPriceEnabled: true
};
